<!--地址管理-新增/编辑 -->
<template>
  <span>
    <slot><el-button @click="show" size="small" type="primary">新增地址</el-button></slot>
    <el-dialog
      center
      :visible.sync="dialogFormVisible"
      :title="name"
      width="700px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="textarea-baiduAI-box">
        <el-input
          placeholder="粘贴地址信息，自动识别姓名，电话和地址 如：张三，13312341234，xx市xx区旺xx科技园9栋大楼 "
          placeholder-style="color:#999999;line-height: 1.6;"
          v-model="smartValue"
          type="textarea"
          :rows="5"
          class="textareabox"
        ></el-input>
        <div class="btn-box">
          <el-button class="empty" @click="empty" size="mini">清空</el-button>
          <el-button
            class="recognition"
            type="primary"
            @click="smartStart"
            :loading="smartStartLoading"
            size="mini"
            >地址识别</el-button
          >
        </div>
      </div>
      <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="loading">
        <el-form-item label="联系人" :label-width="formLabelWidth" prop="consignee">
          <el-input v-model="form.consignee" placeholder="收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="formLabelWidth" prop="mobile">
          <el-input v-model="form.mobile" placeholder="收货人手机号（支持虚拟号码13212311111-1234）"></el-input>
        </el-form-item>
        <el-form-item label="联系地址" :label-width="formLabelWidth" prop="value">
          <AreaCascader class="area-width" placeholder="收货人省市区" v-model="form.value" />
        </el-form-item>
        <el-form-item label="详细地址" :label-width="formLabelWidth" prop="addressDetail">
          <el-input v-model="form.addressDetail" maxlength="120" placeholder="收货人详细地址"></el-input>
        </el-form-item>
        <el-form-item label="设为默认地址" :label-width="formLabelWidth" prop="isDefault">
          <el-radio v-model="form.isDefault" label="1">是</el-radio>
          <el-radio v-model="form.isDefault" label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button @click="submit('ruleForm')" type="primary" :loading="saveLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </span>
</template>

<script>
import {
  create,
  update,
  getById,
  getBaiduAIaddressRecognition,
  findAreasByAddress,
} from '@/api/address/address';
import AreaCascader from '@/components/AreaCascader';
import { debounce } from '@/utils/enhance';
import { validatePhoneVirtual } from '@/common/validator';
export default {
  name: 'AddressManagement',
  components: {
    AreaCascader,
  },
  props: {
    id: String,
    // 是否展示地址弹窗
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialogFormVisible(v) {
      if (v) {
        this.smartValue = '';
      }
    },
  },
  data() {
    return {
      smartStartLoading: false,
      smartValue: '',
      dialogFormVisible: false,
      name: '新增收货地址',
      form: {
        value: null,
        consignee: '',
        mobile: '',
        addressDetail: '',
        provinceId: '',
        cityId: '',
        zoneId: '',
        isDefault: '0',
      },
      saveLoading: false,
      loading: false,
      rules: {
        consignee: [{ required: true, message: '必填信息', trigger: 'blur' }],
        value: [{ required: true, message: '必填信息', trigger: 'blur' }],
        mobile: [
          { required: true, message: '必填信息', trigger: 'blur' },
          { validator: validatePhoneVirtual, trigger: 'blur' },
        ],
        addressDetail: [{ required: true, message: '必填信息', trigger: 'blur' }],
        isDefault: [{ required: true, message: '必填信息', trigger: 'blur' }],
      },
      formLabelWidth: '110px',
    };
  },
  methods: {
    // 智能识别地址清空
    empty() {
      this.smartValue = '';
    },
    // 开始智能识别地址
    /*  city (string, optional): 市 ,
        cityCode (string, optional): 城市国标code ,
        county (string, optional): 区 ,
        countyCode (string, optional): 区县国标code ,
        detail (string, optional): 详细地址 ,
        person (string, optional): 姓名，如果出现多个则按顺序输出 ,
        phonenum (string, optional): 电话号码，如果出现多个则按顺序输出 ,
        province (string, optional): 省 ,
        provinceCode (string, optional): 省国标code ,
        town (string, optional): 街道（乡/镇） ,
        townCode (string, optional): 街道/乡镇国标code  */
    smartStart: debounce(async function () {
      if (!this.smartValue || this.smartValue.trim().length === 0) {
        this.$message.warning('请输入智能识别地址');
        return;
      }
      this.smartStartLoading = true;
      let regionData;
      try {
        regionData = await getBaiduAIaddressRecognition(this.smartValue);
      } catch (e) {
        // this.$message.error('识别接口报错');
        this.smartStartLoading = false;
        console.error(e);
      }
      try {
        if (regionData.success) {
          this.smartStartLoading = false;
          const { person, phonenum, town, detail, province, city, county } = regionData.data;
          this.form.consignee = person;
          this.form.mobile = phonenum;
          this.form.addressDetail = town + detail;
          const region = [province, city, county];
          const address = region.join('');
          if (address) {
            const findAreasData = await findAreasByAddress(address);
            if (findAreasData.success) {
              const provinceId = findAreasData.data.first.id;
              const cityId = findAreasData.data.second.id;
              let zoneId = '';

              try {
                zoneId = findAreasData.data.third.id;
              } catch (error) {
                zoneId = '';
              }
              if (provinceId || cityId || zoneId) {
                this.form.value = [provinceId, cityId, zoneId];
              }
            }
          }
          this.$message.warning('识别成功，请检查是否准确，如有错漏请及时修正。');
        } else {
          this.smartStartLoading = false;
          this.$message.error('地址识别失败');
        }
      } catch (e) {
        // this.$message.error('识别接口报错');
        this.smartStartLoading = false;
        console.error(e);
      }
    }),
    show() {
      this.dialogFormVisible = true;

      this.initData();
    },
    cancel() {
      this.handleClose();
    },
    handleClose() {
      this.form = {
        consignee: '',
        mobile: '',
        addressDetail: '',
        provinceId: '',
        cityId: '',
        zoneId: '',
        isDefault: '0',
        value: [],
      };
      this.dialogFormVisible = false;
    },
    initData() {
      if (this.id) {
        this.name = '编辑收货地址';
        this.fetchAddress();
      }
    },
    fetchAddress() {
      this.loading = true;
      return getById(this.id)
        .then((response) => {
          const address = response.data;
          address.value = [address.provinceId, address.cityId, address.zoneId];
          this.form = address;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit(form) {
      const value = this.form.value || [];
      const [provinceId, cityId, zoneId] = value;
      Object.assign(
        this.form,
        provinceId && { provinceId },
        cityId && { cityId },
        zoneId && { zoneId }
      );
      this.listLoading = true;
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return false;
        }
        const user = { ...this.form };
        if (this.id) {
          user.id = this.id;
        }
        const request = this.id ? update : create;
        this.saveLoading = true;
        request(user)
          .then((response) => {
            this.$message.success('操作成功');
            this.$emit('sureRefresh', user);
            this.handleClose();
          })
          .finally(() => {
            this.saveLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.codeurl-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.code {
  width: 100px;
  height: 100px;
}
/deep/.el-cascader {
  width: 100%;
}
/deep/ .el-dialog__body {
  padding-bottom: 0px;
}
/deep/ .el-form {
  font-size: 12px;

  .el-form-item__label {
    font-size: 12px;
    color: #333;
  }
  .el-radio__label {
    font-size: 12px;
  }
}
.el-button + .el-button {
  margin-left: 20px;
}
.textarea-baiduAI-box {
  width: 100%;
  .textareabox {
    width: 100%;
    margin-bottom: 10px;
  }
  .btn-box {
    text-align: right;
    margin-bottom: 10px;
  }
}
</style>
