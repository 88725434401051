import { withExtTenantIdRequest } from '@/utils/request';

// 获取收货地址列表-分页
export function list(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/listMyPage',
    method: 'post',
    data
  });
}
// 获取收货地址列表-不分页
export function listMyPage(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/listMyAll',
    method: 'post',
    data
  });
}
// 删除地址
export function handleDeleteIds(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/deleteByIds',
    method: 'post',
    data
  });
}
// 新增
export function create(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/create',
    method: 'post',
    data
  });
}

// 更新
export function update(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/update',
    method: 'post',
    data
  });
}

// 通过id获取地址
export function getById(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/userConsigneeAddress/getVO?id=${id}`,
    method: 'get'
  });
}

// 获取默认地址
export function getMyDefault() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/getMyDefault',
    method: 'get'
  });
}

// 删除所有地址
export function deleteAllMyAddress() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/userConsigneeAddress/deleteAllMyAddress',
    method: 'post'
  });
}

// 地址智能识别 -  百度
export function getBaiduAIaddressRecognition(address) {
  return withExtTenantIdRequest({
    url: `/ocean/api/baiduAI/addressRecognitionV2`,
    method: 'post',
    data: { address: address }
  });
} 

// 根据省市区中文名称换省市区id
export function findAreasByAddress(address) {
  return withExtTenantIdRequest({
    url: `/common/api/area/listAreasByAddress?address=${encodeURIComponent(address)}`,
    method: 'get'
  });
}